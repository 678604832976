import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import Image from "../components/image"
import SimpleSlider from "../components/slick"

const NotFoundPage = () => (
  <Layout type="home">
    <SEO title="404: Not found" />
    <div id="content">
      <section id="about" className="cSection">
          <div className="cInnerArea withPB">
            <h2 className="rTitle">
            <span className="price_ticket">404: Not Found</span><br/>
              <span className="tableHeader">お探しのページは<br className="cSP"/>存在しない様です。</span>
            </h2>
          </div>
        <div id="instructor">
          <div className="cInnerArea">
            <div className="commentArea">
              <img className="bg_balloontail" src="/svg/bg_balloontail.svg" alt="" />
              <img className="bg_balloon" src="/svg/bg_balloon.svg" alt="" />
              <SimpleSlider>
                <p　className="liner">You just hit a route that doesn&#39;t exist... <br/>the sadness.</p>
                <p　className="liner">お探しのページ・画像・コンテンツなどは当サイト内には存在しない様です。<br/>お手数ですが、サイトTOPからもう一度お探しください。</p>
                <p　className="liner">残念ながらお見せするコンテンツが見つかりませんので、ここにはChihaのプロフィールを置いておきます。<br/>みんな！教室で会おうね！</p>
              </SimpleSlider>
              <p className="btn1"><Link to="/"> <Button>サイトTOPへ</Button> </Link></p>
            </div>
            <figure className="thumb"><Image filename="prof_chiha@2x.jpg" alt="" /></figure>
              <dl className="chiha">
                <dt className="title"><img src="/svg/text1_2.svg" alt="" /></dt>
                <dd className="note">20代の頃よりヨーガを習い始める。英語では現在、J-shine（小学校英語指導者資格）の資格を生かし、小学校英語指導補助員として活動しているかたわら、10年以上ヨーガを続ける。ヨーガの楽しさとワクワクを伝えるため、2020年、ヨーガインストラクターの資格を取得。2021年よりイングリッシュ ヨーガ 春日町984教室をスタート。</dd>
              </dl>
              <dl className="pooh">
                <dt className="title">ぷーちゃん</dt>
                <dd><p className="note">教室敷地内に住む家ねこ。ヨーガが得意。普段は自由気ままに暮らしているが、仲良しの子やねこ好きの人の気配を感じるとたまに顔を出す。</p>
                <figure><Image filename="prof_pooh@2x.png" alt="" /></figure>
                </dd>
              </dl>
          </div>
        </div>
      </section>
    </div>
    {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Ojisan />
    </div>
    <Tst siteTitle="fdsafdafafds" />
    <Link to="/page-2/">Go to page 2</Link> <br />
    <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */}
  </Layout>

)

export default NotFoundPage
